<template>
	<div>
	<ContentHeader title="Administrasi" url="/administrasi" subTitle="Data Jasa Pemeriksaan" />
     <div class="content-header">
		<div class="container-fluid">
			<div class="row justify-content-center">
	            <div class="col">
	            	<div class="card">
	            		<div class="card-header row d-flex align-items-center">
	            			<h3 class="card-title col-6">Table Jasa Pemeriksaan</h3>
	            			<div class="col-6 text-right">
	            				<router-link to="/tambah-data-jasa"><button class="btn btn-sm btn-info">Tambah</button></router-link>	
	            			</div>
	            		</div>
	            		<div class="card-body">
	            			<table class="table table-bordered table-hover">
							  <thead>
							    <tr>
							      <th scope="col">KODE</th>
							      <th scope="col">POLI</th>
							      <th scope="col">NAMA JASA</th>
							      <th scope="col">HARGA</th>
							      <th scope="col">ACTION</th>
							    </tr>
							  </thead>
							  <tbody>
							    <tr v-for="row in administrasi" :key="row.id">
							      <td scope="row">{{ row.kode_administrasi }}</td>
							      <td>{{ row.poli.nama }}</td>
							      <td>{{ row.nama }}</td>
							      <td>{{ format_nominal(row.harga, 0, ',', '.') }}</td>
							      <td>
							      	<div class="btn-group">
					                    <button type="button" class="btn btn-sm btn-info">Proses</button>
					                    <button type="button" class="btn btn-sm btn-info dropdown-toggle dropdown-icon" data-toggle="dropdown" aria-expanded="false">
					                      <span class="sr-only"></span>
					                    </button>
					                    <div class="dropdown-menu" role="menu" style="">
					                      <router-link :to="`/edit-data-jasa/${row.id}`" class="dropdown-item">Edit</router-link>
					                      <div class="dropdown-divider"></div>
					                      <button @click="hapusDataAdministrasi(row.id)" class="dropdown-item text-danger">Hapus</button>
					                    </div>
					                  </div>
							      </td>
							    </tr>
							  </tbody>
							</table>
							<p class="text-center mt-2">{{cekData}}</p>
	            		</div>
	            	</div>
	            </div>
	        </div>
		</div>
	</div>
    </div>
</template>

<script>
import ContentHeader from '@/components/ContentHeader2'

import axios from 'axios'
import store from '@/store'
import { ref, onMounted, computed } from 'vue' 
import Swal from 'sweetalert2'
import format_nominal from '@/format_nominal.js'
// import router from '@/router'


export default{
	components: {
		ContentHeader: ContentHeader,
	},
    setup(){
    	const administrasi = ref([])
    	const cekData = ref()
    	const user = computed(() => store.getters['auth/user'])
    	const getAdministrasi = async () => {
    		let response = await axios.get('api/administrasi/getWhereJasa/' + user.value.cabang_id)
    		if (response.data == 'kosong') {
    			cekData.value = 'Data Masih Kosong'
    			administrasi.value = null
    		} else {
    			cekData.value = ''
    			administrasi.value = response.data
    		}
    	}

    	const cabang = ref('')

		const getCabang = async () => {
			let { data } = await axios.get(`api/cabang/getWhereId/${user.value.cabang_id}`)
			
			cabang.value = data
		}

    	const hapusDataAdministrasi = (id) => {
    		axios.delete(`api/administrasi/destroy/${id}`)
    		.then((response) => {
    			Swal.fire({
				  title: 'Berhasil!',
				  text: 'Berhasil Menambahkan Data',
				  icon: 'success',
				  showConfirmButton: false,
				  timer: 1500
				})	
				getAdministrasi()
    			console.log(response.data)
    		})
    		.catch((error) => {
    			console.log(error)
    		})
    	}

    	onMounted(() => {
    		getCabang()
    		getAdministrasi()
    	})

		return {
			administrasi, getAdministrasi, hapusDataAdministrasi, format_nominal, cekData, cabang
		}
    }
}
</script>

<style>
	
</style>